import { ref, watch } from "vue";
export function useImage(src) {
  const image = ref(new Image());
  const isLoaded = ref(false);

  if (src === "") {
    return;
  }

  image.value.onload = () => {
    isLoaded.value = true;
  };
  isLoaded.value = false;
  image.value.crossOrigin = "Anonymous";
  image.value.src = src;
  return { image, isLoaded };
}

export function isRightClick(ev) {
  return ev.button === 2;
}

export function isMidClick(ev) {
  return ev.button === 2;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function srcToFile(src, fileName, mimeType) {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}
export function loadImage(image, src) {
  return new Promise((resolve, reject) => {
    const initSRC = image.src;
    const img = image;
    img.crossOrigin = "Anonymous";
    img.onload = resolve;
    img.onerror = (err) => {
      img.src = initSRC;
      reject(err);
    };
    img.src = src;
  });
}
