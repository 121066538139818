/* esm.sh - esbuild bundle(lodash@4.17.20/debounce) esnext production */
if (typeof global === "undefined") var global = window;
var ce = Object.create,
  S = Object.defineProperty,
  se = Object.getPrototypeOf,
  ue = Object.prototype.hasOwnProperty,
  be = Object.getOwnPropertyNames,
  le = Object.getOwnPropertyDescriptor,
  ge = (e) => S(e, "__esModule", { value: !0 }),
  a = (e, r) => () => (
    r || ((r = { exports: {} }), e(r.exports, r)), r.exports
  ),
  de = (e, r, n) => {
    if ((ge(e), (r && typeof r == "object") || typeof r == "function"))
      for (let i of be(r))
        !ue.call(e, i) &&
          i !== "default" &&
          S(e, i, {
            get: () => r[i],
            enumerable: !(n = le(r, i)) || n.enumerable,
          });
    return e;
  },
  me = (e) =>
    e && e.__esModule
      ? e
      : de(
          S(e != null ? ce(se(e)) : {}, "default", {
            value: e,
            enumerable: !0,
          }),
          e
        ),
  x = a((rr, E) => {
    function pe(e) {
      var r = typeof e;
      return e != null && (r == "object" || r == "function");
    }
    E.exports = pe;
  }),
  N = a((tr, L) => {
    var ye =
      typeof global == "object" && global && global.Object === Object && global;
    L.exports = ye;
  }),
  O = a((nr, G) => {
    var Te = N(),
      je = typeof self == "object" && self && self.Object === Object && self,
      Se = Te || je || Function("return this")();
    G.exports = Se;
  }),
  w = a((ir, R) => {
    var xe = O(),
      Oe = function () {
        return xe.Date.now();
      };
    R.exports = Oe;
  }),
  v = a((or, P) => {
    var ve = O(),
      qe = ve.Symbol;
    P.exports = qe;
  }),
  $ = a((ar, W) => {
    var C = v(),
      M = Object.prototype,
      he = M.hasOwnProperty,
      Ie = M.toString,
      m = C ? C.toStringTag : void 0;
    function _e(e) {
      var r = he.call(e, m),
        n = e[m];
      try {
        e[m] = void 0;
        var i = !0;
      } catch (b) {}
      var c = Ie.call(e);
      return i && (r ? (e[m] = n) : delete e[m]), c;
    }
    W.exports = _e;
  }),
  B = a((fr, A) => {
    var ke = Object.prototype,
      Ee = ke.toString;
    function Le(e) {
      return Ee.call(e);
    }
    A.exports = Le;
  }),
  H = a((cr, F) => {
    var U = v(),
      Ne = $(),
      Ge = B(),
      Re = "[object Null]",
      we = "[object Undefined]",
      D = U ? U.toStringTag : void 0;
    function Pe(e) {
      return e == null
        ? e === void 0
          ? we
          : Re
        : D && D in Object(e)
        ? Ne(e)
        : Ge(e);
    }
    F.exports = Pe;
  }),
  z = a((sr, X) => {
    function We(e) {
      return e != null && typeof e == "object";
    }
    X.exports = We;
  }),
  K = a((ur, J) => {
    var Ce = H(),
      Me = z(),
      $e = "[object Symbol]";
    function Ae(e) {
      return typeof e == "symbol" || (Me(e) && Ce(e) == $e);
    }
    J.exports = Ae;
  }),
  Z = a((br, Q) => {
    var V = x(),
      Be = K(),
      Y = 0 / 0,
      Fe = /^\s+|\s+$/g,
      Ue = /^[-+]0x[0-9a-f]+$/i,
      De = /^0b[01]+$/i,
      He = /^0o[0-7]+$/i,
      Xe = parseInt;
    function ze(e) {
      if (typeof e == "number") return e;
      if (Be(e)) return Y;
      if (V(e)) {
        var r = typeof e.valueOf == "function" ? e.valueOf() : e;
        e = V(r) ? r + "" : r;
      }
      if (typeof e != "string") return e === 0 ? e : +e;
      e = e.replace(Fe, "");
      var n = De.test(e);
      return n || He.test(e) ? Xe(e.slice(2), n ? 2 : 8) : Ue.test(e) ? Y : +e;
    }
    Q.exports = ze;
  }),
  te = a((lr, ee) => {
    var Je = x(),
      q = w(),
      re = Z(),
      Ke = "Expected a function",
      Qe = Math.max,
      Ve = Math.min;
    function Ye(e, r, n) {
      var i,
        c,
        b,
        u,
        o,
        s,
        l = 0,
        h = !1,
        g = !1,
        y = !0;
      if (typeof e != "function") throw new TypeError(Ke);
      (r = re(r) || 0),
        Je(n) &&
          ((h = !!n.leading),
          (g = "maxWait" in n),
          (b = g ? Qe(re(n.maxWait) || 0, r) : b),
          (y = "trailing" in n ? !!n.trailing : y));
      function T(t) {
        var f = i,
          d = c;
        return (i = c = void 0), (l = t), (u = e.apply(d, f)), u;
      }
      function ie(t) {
        return (l = t), (o = setTimeout(p, r)), h ? T(t) : u;
      }
      function oe(t) {
        var f = t - s,
          d = t - l,
          k = r - f;
        return g ? Ve(k, b - d) : k;
      }
      function I(t) {
        var f = t - s,
          d = t - l;
        return s === void 0 || f >= r || f < 0 || (g && d >= b);
      }
      function p() {
        var t = q();
        if (I(t)) return _(t);
        o = setTimeout(p, oe(t));
      }
      function _(t) {
        return (o = void 0), y && i ? T(t) : ((i = c = void 0), u);
      }
      function ae() {
        o !== void 0 && clearTimeout(o), (l = 0), (i = s = c = o = void 0);
      }
      function fe() {
        return o === void 0 ? u : _(q());
      }
      function j() {
        var t = q(),
          f = I(t);
        if (((i = arguments), (c = this), (s = t), f)) {
          if (o === void 0) return ie(s);
          if (g) return clearTimeout(o), (o = setTimeout(p, r)), T(s);
        }
        return o === void 0 && (o = setTimeout(p, r)), u;
      }
      return (j.cancel = ae), (j.flush = fe), j;
    }
    ee.exports = Ye;
  }),
  ne = me(te()),
  Ze = ne.default;
export { Ze as default };
