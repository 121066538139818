<script setup>
import reset_icon from "./assets/reset.svg";
import back_icon from "./assets/back.svg";
import move_icon from './assets/move.svg'
import next_icon from "./assets/next.svg";
import zoom_cion from "./assets/zoom.svg";
import original_cion from "./assets/original.svg";
import { ref, watch, onMounted, defineProps, defineEmits, nextTick, onUnmounted } from "vue";
import VueZoomer from "./zoomer/index.vue";
import BackBtn from "@/components/boolv-ui/back-btn";
import { useDebounceFn } from '@vueuse/core'
import UnloadHandler from "@/utils/beforeunload.js";
import {
  useImage,
  isRightClick,
  isMidClick,
  dataURLtoFile,
  loadImage,
} from "./config.js";
import {
  MessageSuccess,
  MessageError,
} from "@/components/boolv-ui/Message/index.js";
import { useWindowSize } from "@vueuse/core";
import { uploadImageSingle } from "@/api/upload/upload.js";
import { inpaint } from "@/api/models/eraser.js";
import { getFileFromUrl, authenticator } from "@/utils/index.js";
import { downloadImageFromUrl } from "@/utils/index.js";
import { eventTracking } from "@/utils/eventTracking.js";
import { useUserStore, useCheckResourceStore, useSideStore, useViewStore } from '@/store/index.js'
const user = useUserStore();
const sideStore = useSideStore();
const viewStore = useViewStore();
const TOOLBAR_SIZE = 250;
const MIN_BRUSH_SIZE = 20;
const MAX_BRUSH_SIZE = 500;
const BRUSH_COLOR = "rgba(204, 255, 0, 0.6)";

// interface Line {
//   size?: number
//   pts: { x: number; y: number }[]
// }

// type LineGroup = Array<Line>
const emits = defineEmits(["back"]);
const props = defineProps({
  imageObj: Object,
});

const zoomed = ref(false);
const initScaleValue = ref(1);
const scale = ref(1);
const imageAspectRatio = ref(1);
const zoomer = ref(null);

const flag = ref(true);

const isLoading = ref(false);
const showBrush = ref(false);
const showRefBrush = ref(false);
const isPanning = ref(false);
const isInpainting = ref(false); //正在修复
const isDraging = ref(false); //正在绘制
const isChangingBrushSizeByMouse = ref(false);
const downloadId = ref("");

const x = ref(-1);
const y = ref(-1);
const { image, isLoaded } = useImage(props.imageObj.originUrl);
const showOriginal = ref(false);
const sliderPos = ref(0);
const maskCanvas = ref(document.createElement("canvas"));

// redo 相关
const renders = ref([]);
const redoRenders = ref([]);
const runMannually = ref(false);

const brushSize = ref(60);
const lineGroups = ref([]);
const curLineGroup = ref([]);
const canvas = ref(null);
const currentImageSrc = ref("");

//埋点上报相关
const currentImageList = ref([]);

//button 状态
const resetDisabled = ref(true);
const undoDisabled = ref(true);
const redoDisabled = ref(true);

const buttonDisabled = {
  opacity: 0.3,
  pointerEvents: "none",
};

//创建三个button状态的watch
UnloadHandler(true);

watch(
  () => renders.value.length,
  (newLength) => {
    if (newLength !== 0) {
      undoDisabled.value = false;
    } else {
      undoDisabled.value = true;
    }
  }
);

watch(
  () => redoRenders.value.length,
  (newLength) => {
    if (newLength !== 0) {
      redoDisabled.value = false;
    } else {
      redoDisabled.value = true;
    }
  }
);

watch([showBrush], () => {
  getCursor();
});

watch([scale], () => {
  console.log("--->", scale.value);
});

watch(isLoaded, (isLoaded) => {
  if (!isLoaded) return;
  initScale();
  drawOnCurrentRender([]);
});


const handleIframeDomIsVisible = (isVisible) => {
  localStorage.setItem('announcementBoard', isVisible ? true : false)
}

const observeDom = async () => {
  await nextTick()
  handleIframeDomIsVisible(false)
  var callback = function () {
    const iframeDom = document.getElementsByTagName('iframe')[3].contentDocument

    const subDom = iframeDom?.getElementsByClassName('intercom-block-paragraph')[0]
    console.log('IRintercom-banner-frame', iframeDom.name)
    console.log('iframeDom', iframeDom)
    console.log('subDom', subDom)
    console.log('变化了=====')
    handleIframeDomIsVisible(subDom)
  };

  var observer = new MutationObserver(callback)
  var options = {
    attributes: true,
    childList: true,
    subtree: true,
  };
  observer.observe(document.body, options);
}


onMounted(() => {
  // viewStore.setMinViewWidth('900px')
  currentImageSrc.value = props.imageObj.originUrl;
  onKeyUp()
  observeDom()
});


const handlePanning = () => {
  isPanning.value = !isPanning.value;
}
//监听键盘事件
const onKeyUp = () => {
  document.addEventListener("keydown", function (e) {
    if (e.key === " ") {
      isPanning.value = true
    }
  })
  document.addEventListener("keyup", function (e) {
    if (e.key === " ") {
      isPanning.value = false
    }
  })
}
//撤销
const handleUndo = () => {
  //redo
  //操作renders
  undoRender();
  //重画当前canvas
};
const undoRender = () => {
  if (!renders.value.length) {
    return;
  }
  const lastRender = renders.value.pop();
  redoRenders.value = [...redoRenders.value, lastRender];
  const newRenders = [...renders.value];
  renders.value = newRenders;
  if (newRenders.length === 0) {
    draw(image.value, []);
  } else {
    draw(newRenders[newRenders.length - 1], []);
  }
};

// 恢复
const handleRedo = () => {
  redoRender();
};
const redoRender = () => {
  if (redoRenders.value.length === 0) {
    return;
  }

  const render = redoRenders.value.pop();
  const newRenders = [...renders.value, render];
  renders.value = newRenders;
  draw(newRenders[newRenders.length - 1], []);
};
const handleOriginal = () => { };

const handleOriginalMouseDown = (ev) => {
  ev.preventDefault();
  showOriginal.value = true;
  window.setTimeout(() => {
    sliderPos.value = 100;
  }, 10);
};
const handleOriginalMouseUp = () => {
  sliderPos.value = 0;
  window.setTimeout(() => {
    showOriginal.value = false;
  }, 300);
};
const handleReset = () => {
  zoomer.value.reset();
};

const handleZoomin = () => {
  zoomer.value.zoomIn(2);
};
//
const handleSliderChange = () => { };

const { width, height } = useWindowSize();

const initScale = () => {
  if (!isLoaded) {
    return;
  }
  const rW = width.value / image.value.naturalWidth;
  const rH = (height.value - TOOLBAR_SIZE) / image.value.naturalHeight;
  let s = 1.0;
  if (rW < 1 || rH < 1) {
    s = Math.min(rW, rH);
  }
  scale.value = s;
  initScaleValue.value = s;
};

const onMouseMove = (ev) => {
  x.value = ev.pageX;
  y.value = ev.pageY;
};

const onPointerUp = (ev) => {
  if (isMidClick(ev)) {
    isPanning.value = false;
  }

  if (isPanning.value) {
    return;
  }

  if (isInpainting.value) {
    return;
  }

  if (!isDraging.value) {
    return;
  }

  if (runMannually.value) {
    isDraging.value = false;
  } else {
    runInpainting();
  }
};

const getCurScale = () => {
  let s = 0.6;
  if (scale.value !== undefined) {
    s = scale.value;
  }
  return s;
};

const getBrushStyle = (_x, _y) => {
  console.log('_x', _x, _y)
  const currentScale = getCurScale();
  const announcementBoard = localStorage.getItem('announcementBoard')
  const y = announcementBoard == 'true' ? _y - 109.6 : _y - 60
  return {
    width: `${brushSize.value * currentScale}px`,
    height: `${brushSize.value * currentScale}px`,
    left: `${_x}px`,
    top: `${y}px`,
    transform: "translate(-50%, -50%)",
  };
};

const handleBack = () => {
  emits("back");
};

const handleMouseOver = () => {
  toggleShowBrush(true);
  showRefBrush.value = false;
};

const toggleShowBrush = (newState) => {
  if (newState !== showBrush.value && !isPanning.value) {
    showBrush.value = newState;
  }
};

const onMouseDown = (ev) => {
  if (isChangingBrushSizeByMouse.value) {
    return;
  }

  if (isPanning.value) {
    return;
  }
  if (!image.value.src) {
    return;
  }
  if (isInpainting.value) {
    return;
  }
  if (isRightClick(ev)) {
    return;
  }

  isDraging.value = true;

  let lineGroup = [];
  // if (isMultiStrokeKeyPressed.value || runMannually) {
  //   lineGroup = [...curLineGroup]
  // }
  lineGroup.push({ size: brushSize.value, pts: [mouseXY(ev)] });
  curLineGroup.value = lineGroup;
  drawOnCurrentRender(lineGroup);
};

const onMouseDrag = (ev) => {
  if (isPanning.value) {
    return;
  }
  if (!isDraging.value) {
    return;
  }
  if (curLineGroup.value.length === 0) {
    return;
  }
  const lineGroup = [...curLineGroup.value];
  lineGroup[lineGroup.length - 1].pts.push(mouseXY(ev));
  curLineGroup.value = lineGroup;
  drawOnCurrentRender(lineGroup);
};

const mouseXY = (ev) => {
  return { x: ev.offsetX, y: ev.offsetY };
};

const getCursor = () => {
  if (isPanning.value) {
    return "grab";
  }
  if (showBrush.value) {
    return "none";
  }
  return undefined;
};

const draw = (image, lineGroup) => {
  const context = canvas.value.getContext("2d");
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  canvas.value.width = image.naturalWidth;
  canvas.value.height = image.naturalHeight;
  context.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
  drawLines(context, lineGroup);
};
const drawLines = (ctx, lines, color = BRUSH_COLOR) => {
  ctx.strokeStyle = color;
  ctx.lineCap = "round";
  ctx.lineJoin = "round";
  lines.forEach((line) => {
    if (!line?.pts.length || !line.size) {
      return;
    }
    ctx.lineWidth = line.size;
    ctx.beginPath();
    ctx.moveTo(line.pts[0].x, line.pts[0].y);
    line.pts.forEach((pt) => ctx.lineTo(pt.x, pt.y));
    ctx.stroke();
  });
};

const drawLinesOnMask = (_lineGroups) => {
  const context = canvas.value.getContext("2d");
  if (!context?.canvas.width || !context?.canvas.height) {
    throw new Error("canvas has invalid size");
  }
  maskCanvas.value.width = context?.canvas.width;
  maskCanvas.value.height = context?.canvas.height;
  const ctx = maskCanvas.value.getContext("2d");
  if (!ctx) {
    throw new Error("could not retrieve mask canvas");
  }
  _lineGroups.forEach((lineGroup) => {
    drawLines(ctx, lineGroup, "white");
  });
};

const hadDrawSomething = () => {
  return curLineGroup.value.length !== 0;
};

const drawOnCurrentRender = (lineGroup) => {
  if (renders.value.length === 0) {
    draw(image.value, lineGroup);
  } else {
    draw(renders.value[renders.value.length - 1], lineGroup);
  }
};

const runInpainting = async () => {
  console.log("runInpainting");
  isLoading.value = true;
  sideStore.showGlobalMask = true;
  let maskLineGroup = curLineGroup.value;
  if (!hadDrawSomething()) {
    return;
  }
  const newLineGroups = [...lineGroups.value, ...maskLineGroup];
  curLineGroup.value = [];
  eventTracking('booltool_page_edit', { is_batch: false, edit_type: 'erase' });
  isDraging.value = false;
  isInpainting.value = true;
  drawLinesOnMask([newLineGroups]);
  const base = maskCanvas.value.toDataURL("image/jpeg");
  const maskFile = dataURLtoFile(base, "mask.png");
  let targetFile = await getFileFromUrl(image.value.src);

  if (renders.value.length > 0) {
    const lastRender = renders.value[renders.value.length - 1];
    targetFile = await getFileFromUrl(lastRender.src);
  }

  // ----loading start
  //1. 使用图片上传接口分别上传原图和mask图片

  //2. 分别拿到两张图片返回的两个s3 uri，用于调用消除笔的接口

  //3.拿到消除笔的数据后在去做前端的交互逻辑
  // ----loading ending

  /*
  "outputS3Uri": "",
  "outputUrl": "",
  "previewId": ""
  */

  //上传原图
  const originalRes = await uploadImageSingle({ file: targetFile });
  const originalImageS3Uri = originalRes?.data.url;
  const maskRes = await uploadImageSingle({ file: maskFile });
  const maskImageS3Uri = maskRes?.data.url;
  const params = {
    inputS3Uri: originalImageS3Uri,
    maskS3Uri: maskImageS3Uri,
  };
  //请求消除笔接口
  try {
    const { code, data } = await inpaint(params);
    if (code === 0) {
      //outputS3Uri:二次消除时的inputS3Uri --- 返回的一张s3URL就在下次绘制的时候代替inputS3Uri使用
      // outputUrl: 输出的背景路径         --- 返回的真实的图片资源就去绘制到页面上，
      // previewId: 下载时需要携带这个id    --- 下载不做成前端的行为，
      const { outputUrl, previewId, outputS3Uri } = data;
      //埋点
      currentImageList.value[0] = outputS3Uri;
      downloadId.value = previewId;
      //返回的一张s3URL就在下次绘制的时候代替inputS3Uri使用
      const newRender = new Image();
      await loadImage(newRender, outputUrl);
      renders.value = [...renders.value, newRender];
      draw(newRender, []);
    } else {
      MessageSuccess("success");

    }
    isInpainting.value = false;
    isLoading.value = false;
    sideStore.showGlobalMask = false;
  } catch (error) {
    isLoading.value = false;
    isInpainting.value = false;
    sideStore.showGlobalMask = false;
    if (error.code != 101003 && error.code != 101007) {
      MessageError("error");
    }
  }
};

const debouncedFn = useDebounceFn(() => {
  handleDownload()
}, 500)

const handleDownload = async () => {
  //当前页面的canvas的导出
  const cvs = canvas.value
  const url = cvs.toDataURL()
  downloadImageFromUrl(url);

  sessionStorage.setItem('currentImageId', JSON.stringify(currentImageList.value));
  eventTracking("booltool_page_download", { tool_name: "eraser", is_batch: false, is_success: true, })
  // downLoading.value = false
};

onUnmounted(() => {
  sideStore.showGlobalMask = false;
})
</script>

<template>
  <div class="relative">
    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
    </div>
    <div class="editor-container" aria-hidden="true" @mousemove="onMouseMove" @mouseup="onPointerUp" @keyup="onKeyUp">
      <vue-zoomer ref="zoomer" :aspect-ratio="imageAspectRatio" :max-scale="10" :minScale="0.2" :curScale="scale"
        :pivot="cursor" v-model:zoomed="zoomed" v-model:isPanning="isPanning">
        <div :class="{
            'editor-canvas-loading': isInpainting,
            'editor-canvas-container': true,
          }">
          <canvas class="editor-canvas" @mouseover="handleMouseOver" @mouseleave="toggleShowBrush(false)"
            @mousedown="onMouseDown" @mousemove="onMouseDrag" ref="canvas" :style="{
                clipPath: `inset(0 ${sliderPos}% 0 0)`,
                transition: 'clip-path 300ms cubic-bezier(0.4, 0, 0.2, 1)',
                cursor: getCursor(),
              }" />

          <div class="original-image-container" :style="{
              width: `${image.naturalWidth}px`,
              height: `${image.naturalHeight}px`,
              position: 'relative',
            }">
            <div v-if="showOriginal" class="editor-slider" :style="{ marginRight: sliderPos + '%' }" />
            <img class="original-image" :src="currentImageSrc" alt="original" />
          </div>
        </div>
      </vue-zoomer>
      <div class="editor-toolkit-panel">
        <div class="panel-slider">
          <span>Brush</span>
          <a-slider id="slider" :min="MIN_BRUSH_SIZE" :max="MAX_BRUSH_SIZE" v-model:value="brushSize"
            :tooltip-visible="false" @change="handleSliderChange" />
        </div>
        <button class="panel-button " @click="handleReset" :style="!resetDisabled ? buttonDisabled : ''">
          <a-tooltip overlayClassName="eraser-tooltip" arrowPointAtCenter>
            <template #title>Reset</template>
            <img :src="reset_icon" />
          </a-tooltip>
        </button>
        <div :class="isPanning ? 'panel-button isPanning-state' : 'panel-button'" @click="handlePanning">
          <a-tooltip overlayClassName="eraser-tooltip" arrowPointAtCenter>
            <template #title>
              <span>move</span>
              <span :style="{ color: '#8F959E' }"> ( space ) </span>
            </template>
            <img :src="move_icon" />
          </a-tooltip>
        </div>
        <button class="panel-button" @click="handleZoomin">
          <a-tooltip overlayClassName="eraser-tooltip" arrowPointAtCenter>
            <template #title>Enlarge</template>
            <img :src="zoom_cion" />
          </a-tooltip>
        </button>

        <div class="panel-button" @click="handleUndo" :style="undoDisabled ? buttonDisabled : ''">
          <a-tooltip overlayClassName="eraser-tooltip" arrowPointAtCenter>
            <template #title>Undo</template>
            <img :src="back_icon" />
          </a-tooltip>
        </div>
        <div class="panel-button" @click="handleRedo" :style="redoDisabled ? buttonDisabled : ''">
          <a-tooltip overlayClassName="eraser-tooltip">
            <template #title>Redo</template>
            <img :src="next_icon" />
          </a-tooltip>
        </div>
        <div class="panel-button" @click="handleOriginal" @mousedown="handleOriginalMouseDown"
          @mouseup="handleOriginalMouseUp">
          <a-tooltip overlayClassName="eraser-tooltip">
            <template #title>Original</template>
            <img :src="original_cion" />
          </a-tooltip>
        </div>
        <div class="panel-soild"></div>
        <a-button type="text" class="panel-download" @click="debouncedFn">
          <span class="download">Download</span>
        </a-button>
      </div>

      <div class="brush-shape" v-if="showBrush && !isPanning && !isInpainting" :style="getBrushStyle(x, y)" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.editor-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 60px);
}

.top-container {
  position: absolute;
  left: 42px;
  top: 25px;
}

.editor-canvas-container {
  display: grid;
  grid-template-areas: "editor-content";
  row-gap: 1rem;
}

.original-image-container {
  grid-area: editor-content;
  pointer-events: none;
  display: grid;
  grid-template-areas: "original-image-content";

  img {
    grid-area: original-image-content;
  }

  .editor-slider {
    grid-area: original-image-content;
    height: 100%;
    width: 6px;
    justify-self: flex-end;
    background-color: #a9bc1f;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2;
  }
}

.editor-canvas {
  grid-area: editor-content;
  z-index: 2;
}

.brush-shape {
  z-index: 999;
  position: absolute;
  border-radius: 50%;
  background-color: #ccfe00;
  border: 1px solid #ccfe00;
  pointer-events: none;
}

.editor-toolkit-panel {
  position: fixed;
  bottom: 1rem;
  border-radius: 3rem;
  padding: 0.6rem 3rem;
  width: 750px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .panel-slider {
    width: 30%;
    display: flex;
    align-items: center;

    #slider {
      margin-left: 12px;
      width: 100%;
    }
  }

  .panel-soild {
    height: 30px;
    border-left: 1px solid #d0d0d0;
  }

  .panel-button {
    width: 36px;
    height: 36px;

    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #ededed;
      transition: all 0.3s;
    }
  }

  .isPanning-state {
    background: #F2EDFF !important;
  }
}

:global(.eraser-tooltip .ant-tooltip-inner) {
  width: fit-content;
  background: #1f2329;
  color: #ffffff;
  border-radius: 4px;
}

:global(.eraser-tooltip .ant-tooltip-content) {
  width: fit-content;
}

.panel-download {
  cursor: pointer;

  .download {
    color: #060606;

    &:hover {
      color: #875eff;
    }
  }

  .eraser-credit {
    color: #8f959e;
  }
}

.editor-canvas-loading-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  opacity: 0.3;
}

.editor-canvas-loading {
  pointer-events: none;
  animation: pulsing 750ms infinite;
}

@keyframes pulsing {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.75;
    background-color: rgb(240, 240, 255);
  }

  100% {
    opacity: 1;
  }
}

.panel-download:hover {
  background-color: transparent;
}

.panel-download:focus {
  background-color: transparent;
}
</style>
